import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const PrivacyPage = () => (
  <Layout>
    <div className="container">
      <div className="row">
        <div className="col-12 pt-5">
          <h1>Datenschutz</h1>
          <Seo title="Datenschutz" />
          <em>Beim Besuch der Website werden keine personenbezogenen Daten erhoben oder verarbeitet.</em>
          <br />
          <br />
          <em>Datenschutzbeauftragte:</em>
          <br />
          Bei Fragen zum Datenschutz wenden Sie sich bitte an:
          <br />
          Beate Rehm
          <br />
          Töpferei Rehm
          <br />
          Lange Gasse 22
          <br />
          72589 Westerheim
          <br />
          <br />
          <em>Änderungen:</em>
          <br />
          Unter Beachtung der geltenden Datenschutzvorschriften behalte ich es mir vor, jederzeit Änderungen an der
          Datenschutzerklärung vorzunehmen.
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
